<template>
  <NuxtImg
    v-if="media"
    provider="shop"
    :src="path"
    :alt="altText"
    v-bind="$attrs"
    loading="lazy"
    placeholder
  />
  <div
    v-if="allowDefault"
    v-bind="$attrs"
    class="flex items-center justify-center bg-black p-8"
  >
    <Logo class="w-full" white />
  </div>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'

const props = withDefaults(
  defineProps<{
    media: Schemas['Media'] | null | undefined
    alt?: string
    fallback?: boolean
  }>(),
  {
    fallback: true,
  }
)

const showDefault = ref(props.media === null)

const allowDefault = computed(() => props.fallback && showDefault.value)

const altText = computed(() => props.alt || props.media?.alt || '')

const path = computed(() => {
  if (props.media?.url) {
    const url = new URL(props.media.url)
    return url.pathname
  }
  return undefined
})
</script>
